<template>
  <transition name="fade">
    <div class="cookie-panel-wrapper" v-show="notcookie">
      <div class="cookie-panel">
        <!-- <pre>
          {{cookie_api_data}}
        </pre> -->
 
        <h2>
          {{ cookie_api_data.cookie_header_text }} 
          <!--Our use of cookies-->
        </h2>
        <p v-html="cookie_api_data.add_cookie_html"></p>
        <br />

        <!-- We use cookies to enable essential services and functionality on our
          site, to enhance your browsing experience, to provide a better service
          through personalized content, and to collect data on how visitors
          interact with our site. To accept the use of all cookies, click 
          "Accept all". To reject the use of all cookies, click “Reject all”.
          For more information about our use of cookies and to opt-out of
          cookies at any time, please refer to our website <a target="_blank" href="https://google.com">Privacy Notice.</a> -->

        <div class="cookie-options-buttons">
          <button @click="accept_all()">
            {{ cookie_api_data.cookie_accept_button_text }}
            <!-- Accept all cookies -->
          </button>
          <button @click="decline_all()">
            {{ cookie_api_data.cookie_reject_button_text }}
            <!-- Reject all cookies -->
          </button>
        </div>

        <hr />

        <h3>{{ cookie_api_data.cookie_required_label_text }}</h3>
        <div v-html="cookie_api_data.add_required_html"></div>

        <hr />

        <div class="cookie-section-header">
          <h3>{{ cookie_api_data.cookie_optional_label_text }}</h3>
          <label class="switch">
            <input
              type="checkbox"
              v-model="optional_checked"
              @change="optional_change()"
            />
            <span class="slider">
              <span class="slider-on">On</span>
              <span class="slider-off">Off</span>
            </span>
          </label>
        </div>
        <div v-html="cookie_api_data.add_optional_html"></div>

        <hr />

        <button @click="accept">Save and close</button>
      </div>
    </div>
  </transition>
</template>
<script>
const axios = require("axios");
const { EncryptStorage } = require("encrypt-storage");
const crypto = require("crypto");
const encryptStorage = new EncryptStorage(process.env.VUE_APP_CHL, {
  storageType: "sessionStorage",
});
export default {
  name: "Cookies",
  props: {
    brandSlug: undefined,
  },
  components: {},
  data() {
    return {
      notcookie: false,
      cookie_api_data: {},
      optional_checked: false,
    };
  },
  methods: {
    accept_all() {
      this.setCookie("cookie_choice_made", "accept", 365);
      this.optional_checked = true;
      this.accept();
    },

    decline_all() {
      this.setCookie("cookie_choice_made", "decline", 365);
      this.optional_checked = false;
      this.accept();
    },

    optional_change() {
      console.log("optional_change", this.optional_checked);

      if (this.optional_checked === true) {
        this.setCookie("cookie_choice_made", "accept", 365);
      } else {
        this.setCookie("cookie_choice_made", "decline", 365);
      }
    },
    accept() {
      this.notcookie = false;

      if (this.optional_checked === true) {
        this.setCookie("cookie_choice_made", "accept", 365);
      } else {
        this.setCookie("cookie_choice_made", "decline", 365);
      }
      //fire off the consent given event
    },
    gensessionid() {
      if (encryptStorage.getItem("search_analytics_id") === undefined) {
        let search_analytics_id = crypto.randomBytes(16).toString("base64");
        encryptStorage.setItem("search_analytics_id", search_analytics_id);
        return search_analytics_id;
      } else {
        return encryptStorage.getItem("search_analytics_id");
      }
    },    
    decline() {
      this.notcookie = false;
      this.setCookie("cookie_choice_made", "decline", 365);
      // fire off the consent denied event
    },
    setCookie(name, value, days) {
      var expires = "";
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },
    getCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
    get_cookie_data() {
      if (this.brandSlug) {
        let api_url =
          "https://devhub.rezcomm.com/hubapmydbauth/brandapi/devcookie.php?brandslug=" +
          this.brandSlug;
        axios
          .post(api_url)
          .then((response) => response)
          .then((data) => {
            this.cookie_api_data = data.data;
            console.log("cookie_data", data);
          });
      }
    },
  },
  mounted() {
    if (this.brandSlug !== "none") {
      let cookieState = this.getCookie("cookie_choice_made");
      if (cookieState) {
        if (cookieState === "accept") {
          this.optional_checked = true;
          this.accept();
        } else {
          this.optional_checked = false;
          this.decline();
        }
      } else {
        this.notcookie = true;
      }

      window.addEventListener(
        "toggle_cookie_settings",
        () => {
          this.notcookie = !this.notcookie;
        },
        false
      );

      this.get_cookie_data();
    } else {
      this.notcookie = false;
    }
  },
};
</script>


<style lang="scss">
.cookie-panel {
  a {
    color: var(--main-cookie-text-color);
    text-decoration: underline !important;
    font-weight: bold;
  }
}
</style>






<style scoped lang="scss">
@import "@/assets/sass/variables.scss";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.cookie-panel-wrapper {
  background: #00000040;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 9999999999999;
  pointer-events: none;
}

.cookie-panel::-webkit-scrollbar {
  width: 9px;
  height: 9px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  height: 6px;
  border: 2px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  border-radius: 7px;
  background-color: var(--main-cookie-text-color);
}

.cookie-panel {
  -ms-overflow-style: 9px; /* IE and Edge */
  scrollbar-width: 9px; /* Firefox */
  --main-cookie-panel-color: #0666ae;
  --main-cookie-text-color: #fff;
  pointer-events: all;
  background: var(--main-cookie-panel-color);
  color: var(--main-cookie-text-color);
  position: fixed;
  // bottom: 0px;
  left: 0%;
  top: 0%;
  // transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  max-width: 60ch;
  max-height: calc(100vh);
  overflow-y: auto;
  box-sizing: border-box;

  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  align-items: center;
  padding: 2rem;
  padding-bottom: 10rem;
  @media screen and (max-width: 576px) {
    padding: 1rem;
    padding-bottom: 10rem;
  }
  font-family: $font-family-medium;
  text-align: left;
  z-index: 9999999999999;
  box-shadow: 0 0px 20px 0 rgb(0 0 0 / 20%);
  a {
    color: var(--main-cookie-text-color);
    text-decoration: underline !important;
    font-weight: bold;
  }

  button {
    background: var(--main-cookie-text-color);
    border: 0px solid var(--main-cookie-text-color);
    color: var(--main-cookie-panel-color);
    padding: 0.5rem 2rem;
    border-radius: 0.25rem;
    font-weight: bold;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }

  .cookie-options-buttons {
    display: flex;
    gap: 1rem;
    padding: 0rem 0 2rem;
    @media screen and (max-width: 600px) {
      flex-direction: column;
    }
  }
}

.switch {
  --switch-width: 4px;
  position: relative;
  display: inline-block;
  width: calc(var(--switch-width) + 60px);
  height: 28px;
  margin: 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider-on,
.slider-off {
  position: absolute;
  user-select: none;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
  color: #fff;
}

.slider-on {
  opacity: 0;
  left: 10px;
}

.slider-off {
  opacity: 1;
  right: 10px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #174569;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 3rem;
  display: flex;
  align-items: center;

  .slider-on {
    opacity: 0;
  }

  .slider-off {
    opacity: 1;
  }
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 3px;
  bottom: 3px;
  background-color: var(--main-cookie-panel-color);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 100%;
  z-index: 333;
}

input:checked + .slider {
  background-color: #174569;

  .slider-on {
    opacity: 1;
  }

  .slider-off {
    opacity: 0;
  }
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(calc(var(--switch-width) + 32px));
  -ms-transform: translateX(calc(var(--switch-width) + 32px));
  transform: translateX(calc(var(--switch-width) + 32px));
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

hr {
  border: 1px solid var(--main-cookie-text-color);
  margin: 1rem 0;
  opacity: 0.25;
}

.cookie-section-header {
  display: flex;
  justify-content: space-between;
}
</style>
